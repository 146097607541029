<template> 
    <v-layout justify-center class="pt-9" fill-height v-if="show">  
        <v-card min-width="200"  outlined id="print_template"  class="px-3 pt-5"> 
            <mbs-page-spinner 
                v-if="loading"
                height="500"/>  
            <div v-else-if="!loading && PRINT_REQUEST" class="same-space not-f3"> 
                <span  v-for="(line,index) in PRINT_REQUEST.lines" :key="index">
                    <v-layout class="my-1" v-if="line.barcode" justify-center> 
                        <mbs-barcode
                            :value="line.text"
                            :options="{
                                height:20,
                                width:1.2,
                                displayValue: false,
                                margin:0
                            }"
                        />
                    </v-layout>
                    
                    <div class="my-1" v-if="line.value=='line'"><hr></div>
                    <div class="my-1" v-else-if="line.value=='doted_line'"><v-divider></v-divider></div>
                    <v-layout v-else class="ma-0"  :justify-center="line.align=='center'?true:false"> 
                        <span v-for="(characters,index) in line.text" :key="index">
                            <span v-if="line.double" class="samess_double"> 
                                <!-- <strong v-if="line.bold">{{ characters!=" "?characters:"  &nbsp; " }}</strong>  -->
                                <span >{{ characters!=" "?characters:"&nbsp;" }}</span>  
                            </span>
                            <span v-else >
                                <span v-if="line.bold"  class="font-weight-bold">{{ characters!=" "?characters:"&nbsp;" }}</span> 
                                <span v-else>{{ characters!=" "?characters:"&nbsp;" }}</span>  
                            </span>
                        </span> 
                    </v-layout> 
                </span>
            </div>
            <br><br>
        </v-card>
    </v-layout> 
</template>

<script> 
    let PAGE_NAME  = 'EPSON-DISPLAY'
    export default {
        props:['show','loading','print_request'], 
        data(){
            return{
                // show:true,
            } 
        }, 
        mounted(){ 
             
        },
        computed:{
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            PRINT_REQUEST(){ 
                return this.print_request
            },

        },
    }
</script>
